import React from "react"
import { graphql } from "gatsby"

import { Cart as Page } from "../components/Cart/Cart"

export const query = graphql`
  query {
    page: sanityPageCart {
      title
      metadata: _rawMetadata(resolveReferences: { maxDepth: 2 })
    }
  }
`

const Component = ({ data, ...props }) => <Page {...props} {...data} />
export default Component
