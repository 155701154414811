import React from "react"

import { withCheckout } from "./withCheckout"
import { Wrapper, ShippingMessage, CheckoutButton, ContinueShopping } from "./CheckoutStyles"
import { Link } from "gatsby"

export const Checkout = withCheckout(
  ({ loading, handleCheckout, continueButton, shippingNotes, additionalCheckoutButton, additionalCheckoutButtonLoading }): JSX.Element => (
    <Wrapper>
      <CheckoutButton onClick={handleCheckout} colour={"yellow"} wide>
        {loading ? additionalCheckoutButtonLoading : additionalCheckoutButton}
      </CheckoutButton>
      <ContinueShopping colour={"black"} as={Link} to={continueButton.url}>
        <span>{continueButton.title}</span>
      </ContinueShopping>
      <ShippingMessage>{shippingNotes}</ShippingMessage>
    </Wrapper>
  )
)
