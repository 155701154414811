import React from "react"

import { CartList } from "./List/CartList"
import { CartPayment } from "./Payment/CartPayment"
import { withCart } from "./withCart"
import { Outer, Inner, Loading } from "./CartStyles"
import { CartEmpty } from "./Empty/CartEmpty"
import { BreadCrumbs } from "../BreadCrumbs/BreadCrumbs"

export const Cart = withCart(
  ({ paths, count, loading }): JSX.Element => (
    <>
      <BreadCrumbs paths={paths} />
      <Outer>
        {loading ? (
          <Loading>Loading...</Loading>
        ) : count > 0 ? (
          <>
            <Inner>
              <CartList />
            </Inner>
            <Inner>
              <CartPayment />
            </Inner>
          </>
        ) : (
          <CartEmpty />
        )}
      </Outer>
    </>
  )
)
