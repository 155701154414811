import React from "react"
import { Link } from "gatsby"

import { withCartEmpty } from "./withCartEmpty"
import { Wrapper, Message } from "./CartEmptyStyles"
import { StyledButton } from "../../Styled/Button"

export const CartEmpty = withCartEmpty(({ continueLink, emptyCartMessage }) => (
  <Wrapper>
    <Message>{emptyCartMessage}</Message>
    <div>
      <StyledButton colour={"yellow"} as={Link} to={continueLink.url}>
        {continueLink.title}
      </StyledButton>
    </div>
  </Wrapper>
))
