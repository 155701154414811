import tw, { styled } from "twin.macro"
import { LargeBody } from "../Styled/Text"

export const Outer = tw.div`
  max-w-lg mx-auto md:px-4 flex flex-wrap
`

export const Inner = styled.div`
  ${tw`w-full md:w-1/2 px-2 py-2 md:pt-4 md:pb-7-2`}
  :first-of-type {
    ${tw`border-b md:border-b-0 md:border-r border-grey`}
  }
`

export const Loading = styled(LargeBody)`
  ${tw`text-center`}
`
