import tw, { styled } from "twin.macro"
import { StyledButton } from "../../../Styled/Button"

export const Wrapper = tw.div`
  flex flex-col
`

export const ShippingMessage = tw.p`
  text-grey text-12
`

export const CheckoutButton = styled(StyledButton)`
  ${tw`mb-1-6`}
`

export const ContinueShopping = styled(StyledButton)`
  ${tw`mb-2`}
`
