import React from "react"

import { withCartPayment } from "./withCartPayment"
import { Wrapper, Title, Main } from "./PaymentStyles"
import { DiscountCode } from "./DiscountCode/DiscountCode"
import { Summary } from "./Summary/Summary"
import { Checkout } from "./Checkout/Checkout"

export const CartPayment = withCartPayment(() => (
  <Wrapper>
    <Title>Payment</Title>
    <Main>
      <DiscountCode />
      <Summary />
      <Checkout />
    </Main>
  </Wrapper>
))
