import tw, { styled } from "twin.macro"
import { Heading12 } from "../../Styled/Text"

export const Wrapper = tw.div`
  w-full md:max-w-34 mx-auto flex flex-col 
`

export const Title = styled(Heading12)`
  ${tw`hidden md:block mb-2-4`}
`

export const Main = tw.div`
  md:border-t py-3-2 flex flex-col border-grey
`
