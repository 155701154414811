import tw, { styled } from "twin.macro"
import { Heading12, Body } from "../../Styled/Text"

export const Wrapper = tw.div`
  w-full md:max-w-34 mx-auto flex flex-col 
`

export const Title = styled(Heading12)`
  ${tw`hidden md:block mb-2-4`}
`

export const LineItems = tw.ul`w-full md:border-t border-grey`

export const Total = styled(Body)`
  ${tw`hidden md:block`}
`
